<template>
  <div class="m-page">
    <section
      class="m-scorebox"
      v-for="(item, index) in scoreCofig"
      :key="index"
    >
      <span> {{ item.DisplayName }} </span>
      <div class="c-scoreitem c-checkbox">
        <el-checkbox
          v-for="(todo, index) in item.ScoremItemGetLines"
          :key="index"
          :disabled="todo.IsSystem"
          v-model="todo.Ischecked"
          >{{ todo.ItemName }}</el-checkbox
        >
      </div>
    </section>

    <el-row class="m-rowbutton">
      <el-button type="primary" size="medium"  @click="onSeve">确定</el-button>
    </el-row>
  </div>
</template>

<script>
import { scoreConfigGetList, scoreConfigEdit } from "@/api/config";
export default {
  name: 'requiredFieldsView',
  data() {
    return {
      scoreCofig: [],
    };
  },
  methods: {
    async onInit() {
      let score = await scoreConfigGetList({type:2});
      if (score) {
        this.scoreCofig = score.scoreCofig;
      }
    },
    async onSeve() {
      let sendData={
        scoreCofig:this.scoreCofig,
        Type:2
      }

      let reslut = await scoreConfigEdit(sendData);
      if(reslut){
        this.$message.success("修改成功")
      }
      this.onInit()
    },
  },
  created() {
    this.onInit();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.m-page {
  font-size: 14px;
  margin-left: 30px;
  margin-top: 20px;

  .el-checkbox {
    margin-right: 20px;
  }
  .el-checkbox__label {
    font-size: 12px;
  }
}
.m-page span {
  font-weight: 600;
}
.m-scorebox {
  line-height: 26px;
  margin-bottom: 15px;
}

.c-scoreitem {
  border: 1px #dddddd solid;
  width: 80%;
  padding: 10px;
}
.m-rowbutton {
  width: 80%;
  text-align: right;
}
</style>
